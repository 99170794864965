import { memo } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box, BoxProps } from "@mui/material";

// ----------------------------------------------------------------------

function PasswordIcon({ ...other }: BoxProps) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  const WARNING_LIGHT = theme.palette.warning.light;

  return (
    <Box {...other}>
      <svg
        width="391"
        height="294"
        viewBox="0 0 391 294"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M217.492 286.618C282.881 274.594 333.482 232.125 344.725 178.442C364.708 83.0712 232.078 94.1261 205.555 69.238C166.92 33.0056 130.847 30.0537 99.9682 45.4641C61.3482 64.7379 92.7333 91.1887 41.6692 123.442C8.43204 144.438 3.81617 173.551 11.6009 200.624C22.6704 239.07 57.8899 269.79 103.875 282.581C132.612 290.568 171.058 295.155 217.492 286.618Z"
          fill={PRIMARY_MAIN}
          fill-opacity="0.25"
        />
        <path
          d="M74.6465 149.907C74.6465 149.907 73.0114 193.997 100.403 219.276C100.403 219.276 122.194 204.343 113.136 154.075L94.7885 149.068L74.6465 149.907Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M82.04 150.501L83.9066 137.304L102.631 139.923L104.57 153.93L94.0211 163.929L82.04 150.501Z"
          fill="white"
          stroke="black"
          stroke-width="0.316"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M94.6722 113.458C93.8184 111.244 78.8711 110.014 76.9321 108.553C75.1958 107.236 73.5317 106.252 71.4915 106.729C68.6265 107.395 66.3113 111.649 65.9496 115.903C65.6312 119.709 65.5299 125.555 66.6441 130.72C69.7551 144.988 79.7682 146.666 79.7682 146.666C84.659 147.042 85.8311 142.152 87.2781 140.516C87.2781 140.502 95.5259 115.672 94.6722 113.458Z"
          fill="black"
        />
        <path
          d="M77.728 129.129C78.3646 140.256 87.9292 150.385 95.1207 148.923C105.423 146.825 105.901 139.084 107.203 128.015C109.012 112.72 100.113 108.22 92.8055 108.639C85.4838 109.059 77.0768 118.001 77.728 129.129Z"
          fill="white"
          stroke="black"
          stroke-width="0.316"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M108.969 105.557C103.948 105.21 97.3352 99.2341 87.8285 97.9752C75.7896 96.3835 72.2734 106.975 72.2734 106.975C72.2734 106.975 74.2558 112.315 84.8767 115.238C95.4975 118.175 109.229 119.969 114.25 113.733C114.25 113.733 118.722 107.815 113.295 101.911C113.281 101.925 112.731 105.818 108.969 105.557Z"
          fill="black"
        />
        <path
          d="M85.7284 130.875C86.326 126.561 84.019 122.677 80.5756 122.2C77.1322 121.723 73.8563 124.834 73.2587 129.148C72.6612 133.462 74.9682 137.346 78.4116 137.823C81.855 138.3 85.1309 135.189 85.7284 130.875Z"
          fill="white"
        />
        <path
          d="M76.5416 133.542C76.7731 133.6 77.0336 133.499 77.1494 133.267C77.5111 132.616 77.9452 132.254 78.495 132.124C79.5658 131.878 80.6945 132.688 80.7089 132.703C80.8681 132.819 81.0562 132.833 81.2298 132.775C81.4035 132.703 81.5337 132.558 81.5627 132.37C82.4453 127.942 79.2764 125.815 79.1317 125.728C78.8713 125.555 78.5385 125.627 78.3648 125.888C78.2057 126.148 78.2635 126.481 78.524 126.654C78.6253 126.727 80.8392 128.232 80.5787 131.386C79.9854 131.111 79.1317 130.865 78.2346 131.053C77.3664 131.241 76.6718 131.806 76.1654 132.746C76.0207 133.007 76.1075 133.354 76.3825 133.499C76.4548 133.513 76.4982 133.528 76.5416 133.542Z"
          fill="black"
        />
        <path
          d="M126.665 155.71C143.219 159.761 144.492 162.815 147.733 171.873L175.081 248.49C177.845 256.565 173.547 265.348 165.473 268.111C157.399 270.875 144.13 268.618 141.366 260.544L117.014 192.203L126.665 155.71Z"
          fill="black"
        />
        <path
          d="M97.2915 162.554L91.6772 162.511L87.7559 205.254C89.8106 213.097 100.229 221.576 100.229 221.576C100.229 221.576 107.362 211.346 108.158 208.597L97.2915 162.554Z"
          fill="white"
        />
        <path
          d="M110.937 153.424L99.0423 166.562L94.166 162.496L104.092 151.644L110.937 153.424Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M99.5925 214.197C99.5925 214.197 118.302 193.23 110.937 153.424C110.937 153.424 119.821 153.018 136.447 158.647L131.672 289.889C131.426 291.885 129.675 293.361 127.664 293.246L44.4769 293.303C42.4945 293.202 40.9318 291.596 40.8594 289.614L40.4832 159.371C40.2951 155.666 43.6087 153.395 47.284 152.961C55.3727 152.006 67.4839 149.502 74.632 149.893C74.6465 149.907 80.2318 194.836 99.5925 214.197Z"
          fill="black"
        />
        <path
          d="M74.6465 149.907L87.8864 166.591L94.1663 162.496L82.0116 149.604L74.6465 149.907Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M77.2943 248.158C77.2943 248.158 95.5262 240.474 112.673 243.18C112.673 243.18 120.096 244.974 112.962 252.079C112.962 252.079 126.246 255.725 130.341 263.655C134.436 271.599 127.07 271.063 127.07 271.063L129.791 276.837C130.789 278.964 130.153 281.496 128.271 282.914L128.112 283.03C126.738 284.057 124.842 283.941 123.598 282.769C124.162 284.694 123.352 286.749 121.644 287.776L121.572 287.819C119.922 288.818 117.81 288.615 116.363 287.313L115.784 286.792L116.204 288.326C116.696 290.091 115.914 291.972 114.323 292.884C112.803 293.752 110.908 293.535 109.62 292.363L108.202 291.075C108.202 291.075 102.805 282.784 92.4876 284.795C90.129 285.258 87.6836 285.157 85.3684 284.549L83.3282 283.999C76.5708 282.205 70.5513 278.327 66.1091 272.944L42.668 258.648L50.308 237.088L77.2943 248.158Z"
          fill="white"
          stroke="black"
          stroke-width="0.316"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M112.977 252.079C112.977 252.079 106.06 249.301 98.29 250.762L112.977 252.079Z"
          fill="white"
        />
        <path
          d="M112.977 252.079C112.977 252.079 106.06 249.301 98.29 250.762"
          stroke="black"
          stroke-width="0.316"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M127.085 271.063C127.085 271.063 120.4 261.832 107.218 259.733L127.085 271.063Z"
          fill="white"
        />
        <path
          d="M127.085 271.063C127.085 271.063 120.4 261.832 107.218 259.733"
          stroke="black"
          stroke-width="0.316"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M123.612 282.784C123.612 282.784 117.448 269.24 101.734 266.462L123.612 282.784Z"
          fill="white"
        />
        <path
          d="M123.612 282.784C123.612 282.784 117.448 269.24 101.734 266.462"
          stroke="black"
          stroke-width="0.316"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M115.784 286.792C115.784 286.792 112.962 278.298 98.8687 274.449L115.784 286.792Z"
          fill="white"
        />
        <path
          d="M115.784 286.792C115.784 286.792 112.962 278.298 98.8687 274.449"
          stroke="black"
          stroke-width="0.316"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M65.4723 177.226L33.5519 243.194C29.7319 250.82 16.1592 253.699 8.53357 249.879C0.907969 246.059 -2.18857 236.784 1.64593 229.144L31.7576 166.837C39.2241 153.192 45.3448 151.34 55.9801 153.8C55.9657 153.8 72.6928 159.081 65.4723 177.226Z"
          fill="black"
        />
        <path
          d="M78.9004 243.571L25.9697 221.504C16.5788 217.453 5.68303 221.793 1.63148 231.184C-2.42008 240.575 1.92087 251.471 11.3118 255.523L62.7665 280.165C62.781 280.165 76.4115 261.658 78.9004 243.571Z"
          fill="black"
        />
        <path
          d="M41.0327 227.755L54.1424 200.639"
          stroke="white"
          stroke-width="0.316"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M35.6646 225.744L78.2782 243.513C78.2782 243.513 78.119 259.994 62.1443 280.107L41.1052 270.036"
          stroke="white"
          stroke-width="0.316"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M125.537 293.419H225.132C226.174 293.419 227.086 292.754 227.419 291.77L251.974 218.35C252.495 216.787 251.337 215.181 249.688 215.181H153.434C152.393 215.181 151.481 215.847 151.148 216.83L125.537 293.419Z"
          fill={PRIMARY_MAIN}
          stroke="black"
          stroke-width="0.316"
          stroke-miterlimit="10"
        />
        <path
          d="M76.7587 287.631H127.475L125.189 293.419H72.9966V291.379C72.9966 289.31 74.6751 287.631 76.7587 287.631Z"
          fill={PRIMARY_MAIN}
          stroke="black"
          stroke-width="0.316"
          stroke-miterlimit="10"
        />
        <path
          d="M376.834 207.801H175.516C167.687 207.801 161.35 201.463 161.35 193.635V14.166C161.35 6.33778 167.687 0 175.516 0H376.834C384.662 0 391 6.33778 391 14.166V193.621C391 201.449 384.662 207.801 376.834 207.801Z"
          fill="black"
        />
        <path
          d="M175.515 201.391C171.232 201.391 167.745 197.904 167.745 193.621V14.1659C167.745 9.88283 171.232 6.3956 175.515 6.3956H376.834C381.117 6.3956 384.604 9.88283 384.604 14.1659V193.621C384.604 197.904 381.117 201.391 376.834 201.391H175.515Z"
          fill="white"
        />
        <path
          d="M373.579 92.1873H180.595C178.931 92.1873 177.585 90.8416 177.585 89.1776V19.3607C177.585 17.6966 178.931 16.351 180.595 16.351H373.579C375.243 16.351 376.588 17.6966 376.588 19.3607V89.1776C376.588 90.8416 375.243 92.1873 373.579 92.1873Z"
          fill="white"
          stroke="black"
          stroke-width="0.316"
          stroke-miterlimit="10"
        />
        <path
          d="M225.465 78.2674C238.723 78.2674 249.471 67.5198 249.471 54.262C249.471 41.0042 238.723 30.2566 225.465 30.2566C212.208 30.2566 201.46 41.0042 201.46 54.262C201.46 67.5198 212.208 78.2674 225.465 78.2674Z"
          fill="white"
          stroke="black"
          stroke-width="0.316"
          stroke-miterlimit="10"
        />
        <path
          d="M255.056 54.2619H225.465V24.6711C241.802 24.6711 255.056 37.9255 255.056 54.2619Z"
          fill="black"
        />
        <path
          d="M225.465 54.262V83.8528C209.114 83.8528 195.875 70.5984 195.875 54.262H225.465Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M277.079 179.049H263.188V188.802H277.079V179.049Z"
          fill="black"
        />
        <path
          d="M300.506 166.533H286.615V188.788H300.506V166.533Z"
          fill="black"
        />
        <path
          d="M323.918 141.457H310.027V188.788H323.918V141.457Z"
          fill="black"
        />
        <path
          d="M347.33 166.533H333.439V188.788H347.33V166.533Z"
          fill="black"
        />
        <path
          d="M370.757 155.174H356.866V188.788H370.757V155.174Z"
          fill="black"
        />
        <path
          d="M278.136 155.536H185.08C180.942 155.536 177.585 152.179 177.585 148.041V111.968C177.585 107.829 180.942 104.472 185.08 104.472H278.136C282.274 104.472 285.631 107.829 285.631 111.968V148.026C285.646 152.179 282.289 155.536 278.136 155.536Z"
          stroke="black"
          stroke-width="0.316"
          stroke-miterlimit="10"
        />
        <path
          d="M273.679 116.063C264.129 115.122 261.322 126.466 261.322 126.466C261.322 126.466 259.788 134.989 250.325 135.843C240.861 136.696 231.485 128.941 231.485 128.941C220.488 123.572 216.914 130.301 213.325 132.862C209.737 135.423 202.458 136.32 197.987 131.835C193.516 127.363 189.551 128.261 189.551 128.261V149.459H273.679V116.063Z"
          stroke="black"
          stroke-width="0.316"
          stroke-miterlimit="10"
        />
        <path
          d="M189.551 128.261C189.551 128.261 193.516 127.363 197.987 131.835C202.458 136.306 209.751 135.409 213.325 132.862C216.899 130.315 220.488 123.572 231.485 128.941C231.485 128.941 240.861 136.697 250.325 135.843C259.788 134.989 261.322 126.466 261.322 126.466C261.322 126.466 264.129 115.136 273.679 116.063"
          stroke="black"
          stroke-width="0.947"
          stroke-miterlimit="10"
        />
        <path
          d="M302.662 26.6968H290.174V39.1843H302.662V26.6968Z"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M302.662 48.0253H290.174V60.5128H302.662V48.0253Z"
          fill="black"
        />
        <path
          d="M302.662 69.3393H290.174V81.8268H302.662V69.3393Z"
          fill="white"
          stroke="black"
          stroke-width="0.316"
          stroke-miterlimit="10"
        />
        <path
          d="M310.649 29.012H341.875"
          stroke="black"
          stroke-width="0.316"
          stroke-miterlimit="10"
        />
        <path
          d="M310.649 36.869H360.729"
          stroke="black"
          stroke-width="0.316"
          stroke-miterlimit="10"
        />
        <path
          d="M310.649 50.3405H341.875"
          stroke="black"
          stroke-width="0.316"
          stroke-miterlimit="10"
        />
        <path
          d="M310.649 58.1975H360.729"
          stroke="black"
          stroke-width="0.316"
          stroke-miterlimit="10"
        />
        <path
          d="M310.649 71.6545H341.875"
          stroke="black"
          stroke-width="0.316"
          stroke-miterlimit="10"
        />
        <path
          d="M310.649 79.5261H360.729"
          stroke="black"
          stroke-width="0.316"
          stroke-miterlimit="10"
        />
      </svg>
    </Box>
  );
}

export default memo(PasswordIcon);
