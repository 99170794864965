// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { Autocomplete, AutocompleteProps } from "@mui/material";

// ----------------------------------------------------------------------

interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  name: string;
}

export default function RHFAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({ name, ...other }: Props<T, Multiple, DisableClearable, FreeSolo>) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Autocomplete size="small" {...field} {...other} />
      )}
    />
  );
}
